@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.container {
    border: 1px dashed color($grey, 600);
    border-radius: 4px;
    position: relative;

    .wrapper {
        background: color($grey, 100);
        border-radius: 4px;
        width: 100%;
    }

    .button {
        align-self: start;
    }

    .description-title {
        align-self: flex-start;
    }

    .private {
        color: color($grey, 900);
        position: absolute;
        top: 16px;
        right: 16px;
        display: flex;
        align-items: center;
    }
}
