@import '../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.profile-badge-ratings {
    &.profile-rating {
        .rating {
            .star {
                width: 16px !important;
                height: 16px !important;
            }

            .score,
            .counts {
                font-size: 16px;
                line-height: 24px;
            }

            .counts {
                color: color($grey, 900);
            }
        }
    }
}

.online-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: color($green);
}
