@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.empty-portfolio {
    .content-wrapper {
        max-width: 540px;
    }

    .image {
        background-image: url('./assets/image.png');
        background-size: cover;
        width: 180px;
        height: 160px;
        align-self: normal;
        flex: none;
    }
}
