@import '../../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.contact-button {
    display: flex;
    width: inherit;

    &[disabled] {
        color: $white;
    }
}
