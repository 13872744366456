@import '../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
// Has to be at least 3000, because 'Messages' button in Header has z-index of 3000
$zIndexBase: 3000;

/** .notable-clients-popover.specific-z-index */
$zIndexNotableClientsPopover: calc(#{$zIndexBase} + 10);
$zIndexStickyHeader: calc(#{$zIndexBase} + 20);
$zIndexLearnMoreModal: $zIndexBase;
$zIndexDropdownMenu: $zIndexLearnMoreModal;
$zIndexDropdownMenuInStickyHeader: calc(#{$zIndexStickyHeader} + 1);

// Necessary to fix hardcoded Z-index in the Package, otherwise Additional Info popover doesn't appear
// Will be removed once we migrate NotableClients to new design
// https://github.com/fiverr/seller_presence_package/blob/2c824c3614e508426398186e0b449ad176cc1ac5/src/components/NotableClients/ClientList/style.scss
@mixin arrows-and-popovers-z-index($zIndexPopover) {
    .dynamic-scrollbar-wrapper.scroll-hider button {
        z-index: $zIndexBase;
    }

    .notable-clients-popover-container.notable-clients-popover {
        z-index: $zIndexPopover;
    }
}

// Necessary to fix hardcoded Z-index in the Package, otherwise Additional Info popover doesn't appear
// Will be removed once we migrate NotableClients to new design
// https://github.com/fiverr/seller_presence_package/blob/2c824c3614e508426398186e0b449ad176cc1ac5/src/components/NotableClients/ClientList/style.scss
body > .notable-clients-popover-container.notable-clients-popover {
    z-index: $zIndexNotableClientsPopover !important;
}

.seller-notable-clients {
    &.wrap-title {
        .notable-clients-title .title {
            font-weight: 700;
        }

        .notable-clients-bar {
            flex-direction: column;

            .scroll-hider {
                margin-left: 0;
            }
        }
    }

    .notable-client-in-list {
        display: flex;
        width: auto;
    }

    &:not(.wrap-title) {
        .notable-clients-bar {
            @include media-query-grid-reverse(sm) {
                flex-direction: column;

                .notable-clients-title {
                    padding-bottom: 14px;
                }

                .dynamic-scrollbar-wrapper {
                    margin-left: 0;
                }
            }
        }

        .notable-clients-title {
            > span {
                font-size: 16px;
                color: color($grey, 1200);
            }

            > span b::after {
                content: ':';
            }
        }

        .dynamic-scrollbar-wrapper {
            margin-left: 8px;
        }
    }

    .notable-clients-bar {
        .scrolled-list {
            position: relative;

            .client-details {
                border-right: none;
            }
        }

        @include arrows-and-popovers-z-index(calc(#{$zIndexNotableClientsPopover} - 1));
    }

    .notable-client-logo-wrap {
        border-radius: 2px;

        &.small {
            width: 32px;
            height: 32px;
        }

        &.medium {
            width: 40px;
            height: 40px;
        }

        .logo-image {
            border-radius: 2px;
        }
    }

    .client-details {
        .industry {
            color: color($grey, 900);
        }

        .client-name {
            color: color($grey, 1100);
        }
    }
}

// README: Case for cover invisible arrows and popovers in a modal
.learn-more-modal {
    @include arrows-and-popovers-z-index(calc(#{$zIndexNotableClientsPopover} + 1));
}
